* {
  font-size: 16px;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  color: #666;
}
.error {
  color: #db1A30FF;
}
/* .uploadAvatar {
  >div {
    >div {
      overflow: hidden;
    }
  }
} */
.email-signature {
  display: flex;
  .button {
    color: #333;
    padding: 0 40px;
    cursor: pointer;
    line-height: 53px;
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid #e5e5e5;
    transition: 0.1s ease-in-out;
    background-color: transparent;
    &:hover {
      border-color: #b2b2b2;
    }
  }
  form,
  .preview {
    padding: 40px;
    flex: 1 1 50%;
  }
  form {
    .spacer {
      margin-bottom: 20px;
      + .spacer {
        margin-top: 20px;
      }
      &.three-col {
        gap: 15px;
        display: flex;
      }
      &.two-col {
        gap: 15px;
        display: flex;
      }
      .input-field {
        width: 100%;
        height: 40px;
        color: #666;
        padding: 0 10px;
        background: #fff;
        border: 1px solid #e5e5e5;
        transition: 0.2s ease-in-out;
        &:focus {
          outline: 0;
          border-color: #1e87f0;
        }
        &::placeholder {
          color: #999;
        }
      }
    }
  }
  .preview {
    textarea {
      width: 530px;
      &:focus-visible {
        outline: 2px dotted #333;
      }
    }
    .button + .button {
      margin-left: 15px;
    }
  }
}
